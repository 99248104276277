import React from 'react';
import './App.scss';
import "../src/scss/styles.scss";
import Index from './routes';
function App() {
  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;
