import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/LandingPage/LandingSports.scss";
import mainImg from "../../components/assets/images/18.jpg";
import sportsimg1 from "../../components/assets/images/19.jpg";
import sportsimg2 from "../../components/assets/images/20.jpg";
const LandingSports = () => {
  return (
    <Container>
      <h1 className="top-title mb-4 pb-2">Sports</h1>
      <Row>
        <Col md={6} className="br-100">
          <img src={mainImg} alt="" className="img-fluid" loading="lazy" />
          <h1 className="main-news-title py-4">
            At 97, Malaysia's Mahathir vows one final fight against
            graft-tainted govt
          </h1>
          <p className="main-news-description hero-news-description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typese- tting,
            remaining essentially unchanged. It was popular- ised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Lorem Ipsum passag- es,
            and more recently with desktop publishing software
          </p>
          <div className="times-ago-news-posted d-flex">
            <h6>16h</h6>
            <div className="times-category-divider"></div>
            <h6>Banking</h6>
          </div>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6}>
              <h1 className="sidebar-news-title">
                England bowlers look to stifle India firepower in semi-final
              </h1>
              <p className="sidebar-news-description">
                Lorem Ipsum is simply dummy text of the printi ng and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever
              </p>
              <div className="times-ago-news-posted d-flex py-3">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Col>
            <Col md={6}>
              <img
                src={sportsimg1}
                alt=""
                loading="lazy"
                className="img-fluid"
              />
            </Col>
          </Row>
          <div className="hr-100 my-4"></div>
          <Row>
            <Col md={6}>
              <h1 className="sidebar-news-title">
                England bowlers look to stifle India firepower in semi-final
              </h1>
              <p className="sidebar-news-description">
                Lorem Ipsum is simply dummy text of the printi ng and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever
              </p>
              <div className="times-ago-news-posted d-flex py-3">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Col>
            <Col md={6}>
              <img
                src={sportsimg2}
                alt=""
                loading="lazy"
                className="img-fluid"
              />
            </Col>
          </Row>
          <div className="hr-100 my-4"></div>
          <Row>
            <Col md={6}>
              <h1 className="sidebar-news-title">
                England bowlers look to stifle India firepower in semi-final
              </h1>
              <p className="sidebar-news-description">
                Lorem Ipsum is simply dummy text of the printi ng and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever
              </p>
              <div className="times-ago-news-posted d-flex py-3">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Col>
            <Col md={6}>
              <img
                src={sportsimg1}
                alt=""
                loading="lazy"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingSports;
