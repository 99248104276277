import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import LandingPage from '../pages/LandingPage';
import ScrollToTop from '../utils/ScrollToTop/ScrollToTop';
const index = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<LandingPage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
};

export default index;