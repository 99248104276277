import React from "react";
import "../../styles/LandingPage/GiveAddHere.scss";
import addHereImg from "../assets/images/addHere.png";
const GiveAddHere = () => {
  return (
    <>
      <img
        src={addHereImg}
        loading="lazy"
        alt=""
        className="img-fluid add-image"
      />
    </>
  );
};

export default GiveAddHere;
