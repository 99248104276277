import React from "react";
import "../../styles/LandingPage/NationalTopNews.scss";
import { Col, Container, Row } from "react-bootstrap";
import mainImg from "../../components/assets/images/07.jpg";
import addImg from "../../components/assets/images/Ad.jpg";
import sidebarimg1 from "../../components/assets/images/03.jpg";
const NationalTopNews = () => {
  return (
    <Container id="national-top-news-container">
      <h1 className="top-title mb-4">Bangladesh</h1>
      <Row>
        {/* 1st Column */}
        <Col md={4}>
          <Row className="flex-column">
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
            <div className="hr-100 my-4"></div>
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
        {/* 2nd Column */}
        <Col md={4}>
          <img src={mainImg} alt="" className="img-fluid" loading="lazy" />
          <h1 className="main-news-title py-4">
            At 97, Malaysia's Mahathir vows one final fight against
            graft-tainted govt
          </h1>
          <p className="main-news-description hero-news-description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry standard dummy text ever
            since the 1500s.
          </p>
          <div className="times-ago-news-posted d-flex">
            <h6>16h</h6>
            <div className="times-category-divider"></div>
            <h6>Banking</h6>
          </div>
        </Col>
        {/* 3rd Column */}
        <Col md={4}>
          <Row className="flex-column">
            <img
              src={addImg}
              loading="lazy"
              alt=""
              className="d-block mx-auto px-5"
            />
            <div className="hr-100 my-4"></div>
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NationalTopNews;
