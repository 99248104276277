import React from "react";
import "../../styles/LandingPage/LandingFeaturesCard2.scss";
import { Col, Container, Row } from "react-bootstrap";
import sidebarimg1 from "../../components/assets/images/27.jpg";
const LandingFeaturesCard2 = () => {
  return (
    <Container fluid id="landing-features-card-2">
      <Container className="pb-5">
        <Row className="mt-4">
          {/* 1st Column */}
          <Col md={3}>
            <h1 className="top-title-white py-4">Youth</h1>
            <Row className="flex-column">
              <Row>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
                <div className="times-ago-news-posted-white d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
            </Row>
          </Col>
          {/* 2nd Column */}
          <Col md={3}>
            <h1 className="top-title-white py-4">Lifestyle</h1>
            <Row className="flex-column">
              <Row>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
                <div className="times-ago-news-posted-white d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
            </Row>
          </Col>
          {/* 3rd Column */}
          <Col md={3}>
            <h1 className="top-title-white py-4">Education</h1>
            <Row className="flex-column">
              <Row>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
                <div className="times-ago-news-posted-white d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
            </Row>
          </Col>
          {/* 4th Column */}
          <Col md={3}>
            <h1 className="top-title-white py-4">Business</h1>
            <Row className="flex-column">
              <Row>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
                <div className="times-ago-news-posted-white d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
              <div className="hr-85 my-4 mx-3"></div>
              <Row>
                <h1 className="sidebar-news-title-white py-3">
                  Twitter to introduce 'Official' label for some verified
                  accounts
                </h1>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default LandingFeaturesCard2;
