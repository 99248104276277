import React from "react";
import "../../styles/LandingPage/LandingMostRead.scss";
import { Col, Container, Row } from "react-bootstrap";
import sidebarimg1 from "../../components/assets/images/03.jpg";
const LandingMostRead = () => {
  return (
    <Container id="landing-most-read-container">
      <h1 className="top-title">Most Read</h1>
      <Row className="my-4 py-2">
        <Col md={3}>
          <img
            src={sidebarimg1}
            alt=""
            loading="lazy"
            className="img-fluid h-100 w-100"
          />
          <div className="most-read-title-section d-flex justify-content-between align-items-center">
            <h1 className="most-read-title">Business</h1>
            <h1 className="most-read-number">1</h1>
          </div>
          <h1 className="sidebar-news-title py-3">
            Twitter to introduce 'Official' label for some verified accounts
          </h1>
        </Col>
        <Col md={3}>
          <img
            src={sidebarimg1}
            alt=""
            loading="lazy"
            className="img-fluid h-100 w-100"
          />
          <div className="most-read-title-section d-flex justify-content-between align-items-center">
            <h1 className="most-read-title">Business</h1>
            <h1 className="most-read-number">2</h1>
          </div>
          <h1 className="sidebar-news-title py-3">
            Twitter to introduce 'Official' label for some verified accounts
          </h1>
        </Col>
        <Col md={3}>
          <img
            src={sidebarimg1}
            alt=""
            loading="lazy"
            className="img-fluid h-100 w-100"
          />
          <div className="most-read-title-section d-flex justify-content-between align-items-center">
            <h1 className="most-read-title">Business</h1>
            <h1 className="most-read-number">3</h1>
          </div>
          <h1 className="sidebar-news-title py-3">
            Twitter to introduce 'Official' label for some verified accounts
          </h1>
        </Col>
        <Col md={3}>
          <img
            src={sidebarimg1}
            alt=""
            loading="lazy"
            className="img-fluid h-100 w-100"
          />
          <div className="most-read-title-section d-flex justify-content-between align-items-center">
            <h1 className="most-read-title">Business</h1>
            <h1 className="most-read-number">4</h1>
          </div>
          <h1 className="sidebar-news-title py-3">
            Twitter to introduce 'Official' label for some verified accounts
          </h1>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingMostRead;
