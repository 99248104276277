import React from "react";
import "../../styles/LandingPage/LandingPageVideos.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import videoImage1 from "../assets/images/15.jpg";
const LandingPageVideos = () => {
  return (
    <Container id="landing-page-video-container">
      <Row>
        <Col md={4}>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={videoImage1}
              className="landing-page-video-card p-3"
            />
            <Card.Body>
              <Card.Title className="video-title">
                Key factors in NZ vs Pak 1st semi-final match
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={videoImage1}
              className="landing-page-video-card p-3"
            />
            <Card.Body>
              <Card.Title className="video-title">
                Key factors in NZ vs Pak 1st semi-final match
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={videoImage1}
              className="landing-page-video-card p-3"
            />
            <Card.Body>
              <Card.Title className="video-title">
                Key factors in NZ vs Pak 1st semi-final match
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageVideos;
