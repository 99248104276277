import React from "react";
import "../../styles/LandingPage/ScienceAndTechnology.scss";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineDown } from "react-icons/ai";
import sidebarimg1 from "../../components/assets/images/02.jpg";
const ScienceAndTechnology = () => {
  return (
    <Container id="science-and-technoloy-container">
      <h1 className="top-title mb-4 pb-3">Science & Technology</h1>
      <Row>
        {/* 1st Column */}
        <Col md={4} className="br-100">
          <Row className="flex-column">
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
            <div className="hr-85 mx-3 my-4"></div>
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
        {/* 2nd Column */}
        <Col md={4} className="br-100">
          <Row className="flex-column">
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
            <div className="hr-85 mx-3 my-4"></div>
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid h-100 w-100"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <p className="sidebar-news-description">
                  Lorem Ipsum is simply dummy text of the prin ting and
                  typesetting Lorem Ipsum has been the industry's standard
                </p>
                <div className="times-ago-news-posted d-flex">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
        {/* 3rd Column */}
        <Col md={4}>
          <Row className="flex-column">
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <div className="times-ago-news-posted d-flex py-3">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
            <div className="hr-85 mx-3 my-4"></div>
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <div className="times-ago-news-posted d-flex py-3">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
            <div className="hr-85 mx-3 my-4"></div>
            <Row>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid"
                />
              </Col>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <div className="times-ago-news-posted d-flex py-3">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <button className="science-technology-show-more-btn d-block mx-auto mt-4">
        Show More
        <span className="ps-3">
          <AiOutlineDown />
        </span>
      </button>
    </Container>
  );
};

export default ScienceAndTechnology;
