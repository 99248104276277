import React from "react";
import "../../styles/LandingPage/LandingOpinion.scss";
import { Col, Container, Row } from "react-bootstrap";
import img2 from "../../components/assets/images/opinionImg.png";

const LandingOpinion = () => {
  return (
    <Container>
      <h1 className="top-title mb-4">Opinion</h1>
      <Container fluid id="landing-opinion-container">
        <Container>
          <Row className="py-5 align-items-center">
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <img src={img2} alt="" loading="lazy" className="img-fluid" />
                </Col>
                <Col md={6}>
                  <h1 className="opinion-title pb-3">Rafiq Khan</h1>
                  <p className="sidebar-news-title-white pb-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Optio possimus adipisci sed, aut dolor sapiente alias? Non
                    magnam saepe.
                  </p>
                  <small className="video-title">10 November, 2022</small>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <img src={img2} alt="" loading="lazy" className="img-fluid" />
                </Col>
                <Col md={6}>
                  <h1 className="opinion-title pb-3">Rasal Khan</h1>
                  <p className="sidebar-news-title-white pb-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Optio possimus adipisci sed, aut dolor sapiente alias? Non
                    magnam saepe.
                  </p>
                  <small className="video-title">10 November, 2022</small>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default LandingOpinion;
