import React from "react";
import "../../styles/LandingPage/LatestNews.scss";
import { Col, Container, Row } from "react-bootstrap";
import mainImg from "../../components/assets/images/07.jpg";
import addImg from "../../components/assets/images/Ad.jpg";
import sidebarimg1 from "../../components/assets/images/03.jpg";

const LatestNews = () => {
  return (
    <Container>
      <div className="latest-news-title-section d-flex">
        <h1 className="top-title me-5">Headlines</h1>
        <h1 className="top-title">Latest News</h1>
      </div>
      <div className="hr-100 my-3"></div>
      <Row className="mt-4">
        {/* 1st Column */}
        <Col md={3} className="br-100">
          <Row className="flex-column">
            <Row>
              <img
                src={sidebarimg1}
                alt=""
                loading="lazy"
                className="img-fluid h-100 w-100"
              />
              <h1 className="sidebar-news-title py-3">
                Twitter to introduce 'Official' label for some verified accounts
              </h1>
              <div className="times-ago-news-posted d-flex">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Row>
            <div className="hr-85 my-4 mx-3"></div>
            <Row>
              <img
                src={sidebarimg1}
                alt=""
                loading="lazy"
                className="img-fluid h-100 w-100"
              />
              <h1 className="sidebar-news-title py-3">
                Twitter to introduce 'Official' label for some verified accounts
              </h1>
              <div className="times-ago-news-posted d-flex">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Row>
          </Row>
        </Col>
        {/* 2nd Column */}
        <Col md={3} className="br-100">
          <Row className="flex-column">
            <Row>
              <img
                src={sidebarimg1}
                alt=""
                loading="lazy"
                className="img-fluid h-100 w-100"
              />
              <h1 className="sidebar-news-title py-3">
                Twitter to introduce 'Official' label for some verified accounts
              </h1>
              <div className="times-ago-news-posted d-flex">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Row>
            <div className="hr-85 my-4 mx-3"></div>
            <Row>
              <img
                src={sidebarimg1}
                alt=""
                loading="lazy"
                className="img-fluid h-100 w-100"
              />
              <h1 className="sidebar-news-title py-3">
                Twitter to introduce 'Official' label for some verified accounts
              </h1>
              <div className="times-ago-news-posted d-flex">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Row>
          </Row>
        </Col>
        {/* 3rd Column */}
        <Col md={3} className="br-100">
          <Row className="flex-column">
            <Row>
              <img
                src={sidebarimg1}
                alt=""
                loading="lazy"
                className="img-fluid h-100 w-100"
              />
              <h1 className="sidebar-news-title py-3">
                Twitter to introduce 'Official' label for some verified accounts
              </h1>
              <div className="times-ago-news-posted d-flex">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Row>
            <div className="hr-85 my-4 mx-3"></div>
            <Row>
              <img
                src={sidebarimg1}
                alt=""
                loading="lazy"
                className="img-fluid h-100 w-100"
              />
              <h1 className="sidebar-news-title py-3">
                Twitter to introduce 'Official' label for some verified accounts
              </h1>
              <div className="times-ago-news-posted d-flex">
                <h6>16h</h6>
                <div className="times-category-divider"></div>
                <h6>Banking</h6>
              </div>
            </Row>
          </Row>
        </Col>
        {/* 4th Column */}
        <Col md={3}>
          <Row className="flex-column">
            <img
              src={addImg}
              loading="lazy"
              alt=""
              className="d-block mx-auto px-5"
            />
            <div className="hr-85 mx-3 my-4"></div>
            <Row>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <div className="times-ago-news-posted d-flex py-3">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid"
                />
              </Col>
            </Row>
            <div className="hr-85 mx-3 my-4"></div>
            <Row>
              <Col md={6}>
                <h1 className="sidebar-news-title">
                  Loans From Forex Made Costlier But Still Cheapest
                </h1>
                <div className="times-ago-news-posted d-flex py-3">
                  <h6>16h</h6>
                  <div className="times-category-divider"></div>
                  <h6>Banking</h6>
                </div>
              </Col>
              <Col md={6}>
                <img
                  src={sidebarimg1}
                  alt=""
                  loading="lazy"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LatestNews;
