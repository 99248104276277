import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "../../styles/LandingPage/LandingEntertainment.scss";
import entertainmentImage1 from "../assets/images/21.jpg";
import entertainmentImage2 from "../assets/images/24.jpg";
const LandingEntertainment = () => {
  return (
    <Container id="landing-page-entertainment-container">
      <h1 className="top-title mb-4">Entertainment</h1>
      <Row>
        <Col md={4}>
          <Card
            style={{ width: "25rem" }}
            className="landing-page-entertainment-card"
          >
            <Card.Img src={entertainmentImage1} className="p-3" />
            <Card.Body>
              <Card.Title className="video-title">
                Key factors in NZ vs Pak 1st semi-final match
              </Card.Title>
              <Card.Text className="sidebar-news-description-white mt-3">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <div className="times-ago-news-posted d-flex py-3 text-white ps-3">
              <h6>16h</h6>
              <div className="times-category-divider"></div>
              <h6>Banking</h6>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            style={{ width: "25rem" }}
            className="landing-page-entertainment-card"
          >
            <Card.Img src={entertainmentImage2} className="p-3" />
            <Card.Body>
              <Card.Title className="video-title">
                Key factors in NZ vs Pak 1st semi-final match
              </Card.Title>
              <Card.Text className="sidebar-news-description-white mt-3">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <div className="times-ago-news-posted d-flex py-3 text-white ps-3">
              <h6>16h</h6>
              <div className="times-category-divider"></div>
              <h6>Banking</h6>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            style={{ width: "25rem" }}
            className="landing-page-entertainment-card"
          >
            <Card.Img src={entertainmentImage1} className="p-3" />
            <Card.Body>
              <Card.Title className="video-title">
                Key factors in NZ vs Pak 1st semi-final match
              </Card.Title>
              <Card.Text className="sidebar-news-description-white mt-3">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <div className="times-ago-news-posted d-flex py-3 text-white ps-3">
              <h6>16h</h6>
              <div className="times-category-divider"></div>
              <h6>Banking</h6>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingEntertainment;
