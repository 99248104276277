import React from "react";
import FeaturesSlider from "../components/LandingPage/FeaturesSlider";
import GiveAddHere from "../components/LandingPage/GiveAddHere";
import HeroNews from "../components/LandingPage/HeroNews";
import LandingEntertainment from "../components/LandingPage/LandingEntertainment";
import LandingFeaturesCard from "../components/LandingPage/LandingFeaturesCard";
import LandingFeaturesCard2 from "../components/LandingPage/LandingFeaturesCard2";
import LandingMostRead from "../components/LandingPage/LandingMostRead";
import LandingOpinion from "../components/LandingPage/LandingOpinion";
import LandingPageVideos from "../components/LandingPage/LandingPageVideos";
import LandingSports from "../components/LandingPage/LandingSports";
import LatestNews from "../components/LandingPage/LatestNews";
import NationalTopNews from "../components/LandingPage/NationalTopNews";
import ScienceAndTechnology from "../components/LandingPage/ScienceAndTechnology";
import TopNews from "../components/LandingPage/TopNews";

const LandingPage = () => {
  return (
    <div>
      <div className="pb-5">
        <GiveAddHere />
        <div className="hr-100"></div>
        <HeroNews />
        <GiveAddHere />
        <FeaturesSlider />
        <NationalTopNews />
        <GiveAddHere />
        <TopNews />
        <LandingPageVideos />
        <LatestNews />
        <LandingFeaturesCard />
        <LandingSports />
        <LandingEntertainment />
        <ScienceAndTechnology />
        <GiveAddHere />
        <LandingFeaturesCard2 />
        <LandingMostRead />
        <LandingOpinion />
      </div>
    </div>
  );
};

export default LandingPage;
